import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getHomeProduct } from "../../../Redux/actions/getHomeProduct";

const HomeFormOne = ({ onFinishFailed, onFinishStep1, form }) => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const { data } = useSelector((state) => state.getHomeProduct);

  useEffect(() => {
    dispatch(getHomeProduct());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.length) {
      form.setFieldValue("ProductID", data[0]?.Product_ID);
    }
  }, [form, data]);

  return (
    <div className="col-sm-8 mForm" style={{backgroundColor: "rgb(30 123 95 / 69%)"}} >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Home Takaful</h3>
        <h5 className="cnhbsh5">Basic Information</h5>
        <Form
          name="sehatFormOne"
          onFinish={onFinishStep1}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
          form={form}
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Email is not valid!",
                  },
                  {
                    required: true,
                    message: "Email is required!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Contact"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message:
                      "Contact number must be in this format 03XX-XXXXXXX",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="ProductID"
                label="Product"
                rules={[
                  {
                    required: true,
                    message: "Product  is required!",
                  },
                ]}
              >
                <Select placeholder="Product">
                  {data.map((item, i) => (
                    <Option key={item.Product_ID} value={item.PRODUCT_ID}>
                      {item.Product_Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormOne;
