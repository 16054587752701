

import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";
import GropAccordian from './GropAccordian';

function Whychosegrop() {
    return (
        <Fragment>

            <div className="container-fluid ">

                <div className="row">
                    <div className="col-sm-12 heads text">

                        <div className='row whychosesalaam gropss'>
                            <div className='col-sm-12 chose'>
                                <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us</h2>
                            </div>

                            <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
                                <h3>Comprehensive Dread Disease Expense Benefit</h3>
                                <p>Recovering from a chronic disease has a significant financial and emotional impact on any family. Our Comprehensive Dread Disease Benefit help cover the costs associated with a member’s illness.</p>
                            </div>

                            <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
                                <h3>Comprehensive Hospitalization Expense Benefit</h3>
                                <p>Comprehensive Hospitalization cover refers to the coverage against any treatment where you need hospitalization for a minimum of one (1) night, for specialized medical attention and care, before, during and after the treatment/surgery.</p>
                            </div>

                            <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
                                <h3>Maternity Expense Benefit</h3>
                                <p>Maternity coverage (optional) is one of the essential health benefits. Salaam Takaful Limited provides cover for pregnancy, labor, delivery, and newborn baby care at an affordable contribution.</p>
                            </div>

                            <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="4000">
                                <h3>Out-patient Expense Benefit</h3>
                                <p>Salaam Takaful Limited provides (optional) Outpatient medical benefit that covers consultations and treatments provided by a specialist or medical practitioner when an overnight stay in hospital is not necessary.</p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            {/* <Fade up delay={500}>
                <div className='container-fluid'>
                    <div className='row claimprocedure grouphealth'>
                        <div className='col-sm-12'>
                            <Fade left delay={700}><h2>Coverage</h2></Fade>
                           <div className='row groups'>
                            <div className='col-sm-6'>
                                <ul>
                                    <li>Daily Room & Board charges</li>
                                    <li>Operation theatre and ICU/CCU charges</li>
                                    <li>Physician, Surgeon and Anaesthetist’s fee</li>
                                    <li>Diagnostic tests during hospitalization</li>
                                    <li>Kidney Dialysis and organ Transplant</li>
                                    <li>Prescribed Medicines used during hospitalization</li>
                                    <li>Blood & Oxygen Supplies</li>
                                    <li>Specialized Investigations (MRI, C.T. Scan, Thallium Scan, Endoscopy, Angioplasty, etc.)</li>
                                    <li>Normal Delivery/C-Section/Multiple Deliveries/Complicated Delivery</li>
                                    <li>Prescribed Medicines used during hospitalization</li>
                                    <li>Pre Natal (9 months)*</li>
                                    <li>Well Baby charges</li>
                                    <li>Conservative treatments</li>
                                </ul>
                            </div>

                            <div className='col-sm-6'>
                            <ul>
                                    <li>Day Care Surgical Procedures</li>
                                    <li>Ventilators and Allied Services</li>
                                    <li>Biopsy charges</li>
                                    <li>Surgical supplies</li>
                                    <li>Accidental Emergency ER treatment</li>
                                    <li>Ambulance charges for emergencies</li>
                                    <li>24 hours pain management (injectables)</li>
                                    <li>Pre & Post Hospitalization expenses (30 Days)</li>
                                    <li>Post Natal (30 days)</li>
                                    <li>NICU charges</li>
                                    <li>Newborn Babies Vaccinations</li>
                                    <li>Mother stays during the Baby’s Hospitalization</li>
                                    <li>Circumcision</li>
                                </ul>
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
            </Fade> */}

            <Fade up delay={500}>
                <div className='container-fluid'>
                    <div className='row claimprocedure personalAccident newWhyChoseGroup'>
                        <div className='col-sm-12'>
                            <Fade left delay={700}><h2>Claim Procedure</h2></Fade>
                            <GropAccordian />
                        </div>
                    </div>
                </div>
            </Fade>
        </Fragment>
    )
}

export default Whychosegrop