import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { postCorporateFinancial } from "../../Redux/actions/postCorporateFinancial";
import { toast } from "react-toastify";
import { clearMessage } from "../../Redux/reducers/postCorporateFinancialReducer";
import { getcorpFinanceCity } from "../../Redux/actions/getcorpFinanceCity";
import { getCorporateFinancialProducts } from "../../Redux/actions/getCorporateFinancialProducts";

const CorporateAndFinancialForm = ({ title, bgImage }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.postCorporateFinancial.error);
  const { data } = useSelector((state) => state.getcorpFinanceCity);
  const productData = useSelector(
    (state) => state.getCorporateFinancialProducts.data
  );
  const onFinishStep1 = async (values) => {
    const selectedProduct = productData.find(
      (product) => product.Product_ID === values.Product_ID
    );

    const payload = {
      ...values,
      Product_ID: selectedProduct?.Product_ID,
      Product_Value: selectedProduct?.Product_Name,
    };

    const resultAction = await dispatch(
      postCorporateFinancial({ values: payload })
    );
    if (postCorporateFinancial.fulfilled.match(resultAction)) {
      toast.success("Form Submitted Successfully");
      form.resetFields();
    }
  };
  const onFinishFailed = (error) => {
    console.log(error);
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [error]);
  useEffect(() => {
    dispatch(getcorpFinanceCity());
  }, [dispatch]);

  useEffect(() => {
    const pathname = window.location.pathname;

    const firstSegment = pathname.split("/")[1];

    if (firstSegment.startsWith("c")) {
      dispatch(getCorporateFinancialProducts("Corporates"));
    } else {
      dispatch(getCorporateFinancialProducts("Financial"));
    }
  }, [dispatch]);
  return (
    <div className="container-fluid motorFomrOne">
      <div
        className="row formTakafulll"
        style={{
          backgroundImage: bgImage,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="col-sm-8 mForm"
          style={{backgroundColor: "rgb(30 123 95 / 69%)"}}
        >
          <div className="centreWidthFOrm">
            <h3 className="cnhbsh3">Salaam {title} Takaful</h3>
            <h5 className="cnhbsh5">Basic Information</h5>
            <Form
              name="sehatFormOne"
              onFinish={onFinishStep1}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              autoComplete="off"
              form={form}
            >
              <div className="row">
                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "Email is not valid!",
                      },
                      {
                        required: true,
                        message: "Email is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </div>

                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Phone"
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                        pattern: /^03\d{2}-\d{7}$/,
                        message:
                          "Phone number must be in this format 03XX-XXXXXXX",
                      },
                    ]}
                    normalize={(value) => {
                      if (!value) {
                        return value;
                      }
                      const onlyNums = value.replace(/[^\d]/g, "");
                      if (onlyNums.length <= 4) {
                        return onlyNums;
                      } else if (onlyNums.length <= 11) {
                        return `${onlyNums.slice(0, 4)}-${onlyNums.slice(
                          4,
                          11
                        )}`;
                      }
                      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                    }}
                  >
                    <Input placeholder="Contact 03XX-XXXXXXX" />
                  </Form.Item>
                </div>
                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name={`City_Code`}
                    label="City"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="City"
                      showSearch
                      filterOption={(inputValue, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    >
                      {data?.map((item, i) => (
                        <Select.Option key={i} value={item.ID}>
                          {item.city_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-sm-12 labelSSS">
                  <Form.Item
                    name="Product_ID"
                    label="Product"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Product"
                      showSearch
                      filterOption={(inputValue, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    >
                      {productData?.map((item, i) => (
                        <Select.Option key={i} value={item.ID}>
                          {item.Product_Name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="col-sm-4 mForm">
            {/* <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div> */}
          </div>
        </div>
        <div className="col-sm-4 mForm"></div>
      </div>
    </div>
  );
};

export default CorporateAndFinancialForm;
