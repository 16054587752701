import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { getToken } from "../../../Redux/actions/getToken";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getTravelType } from "../../../Redux/actions/getTravelType";
import { getTravelProductType } from "../../../Redux/actions/getTravelProductType";
import { getTravelProduct } from "../../../Redux/actions/getTravelProduct";

const TravelFormOne = ({
  onFinishFailed,
  onFinishStep1,
  setProductTypeId,
  form,
  productHeading,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const typeData = useSelector((state) => state.getTravelType.data);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // const productTypeData = useSelector(
  //   (state) => state.getTravelProductType.data
  // );
  const productData = useSelector((state) => state.getTravelProduct.data);
  const handleProductChange = (value) => {
    setSelectedProduct(value);
    getProductType();
  };

  const getProductType = () => {
    if (selectedProduct) {
      const selectedProductData = productData.find(
        (item) => item.PRODUCT_ID === selectedProduct
      );
      setProductTypeId(
        selectedProductData ? selectedProductData.PRODUCT_TYPE : ""
      );
      return selectedProductData ? selectedProductData.PRODUCT_TYPE : "";
    }
    return "";
  };

  const token = Cookies.get("token");
  console.log(productHeading, "<<+++++productHeading");
  useEffect(() => {
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch, token]);
  useEffect(() => {
    dispatch(getTravelType());
    // dispatch(getTravelProductType());
    dispatch(getTravelProduct());
  }, [dispatch, token]);

  useEffect(() => {
    if (productData.length > 0) {
      const matchedProduct = productData.find(
        (item) => item.PRODUCT_NAME == productHeading
      );

      if (matchedProduct) {
        console.log(matchedProduct, "<==========matchedProduct");
        form.setFieldsValue({ Product_ID: matchedProduct.PRODUCT_NAME });
      }
    }
  }, [productData, productHeading]);
  return (
    <div className="col-sm-8 mForm" style={{ backgroundColor: "rgb(30 123 95 / 69%)" }} >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Personal Information</h5>
        <Form
          name="travelFormOne"
          onFinish={onFinishStep1}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 labelSSS">
              <Form.Item
                name="Name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Email is not valid",
                  },
                  {
                    message: "Email is required!",
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Contact"
                label="Contact"
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message: "Enter a valid contact number 03XX-XXXXXXX format",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Type"
                label="Family Type"
                rules={[
                  {
                    required: true,
                    message: "Family Type is required!",
                  },
                ]}
              >
                <Select placeholder="Type">
                  {typeData?.map((item, i) => (
                    <Option key={i} value={item?.ID}>
                      {item?.Type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Product_ID"
                label="Product "
                rules={[
                  {
                    required: true,
                    message: "Product  is required!",
                  },
                ]}
              >
                <Select
                  placeholder="Product"
                  value={selectedProduct}
                  onChange={handleProductChange}
                >
                  {productData.map((item, i) => (
                    <Option key={item.PRODUCT_ID} value={item.PRODUCT_ID}>
                      {item.PRODUCT_NAME}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          {/* <Form.Item
            name="Product_Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Product Type">
              {productTypeData?.map((item, i) => (
                <Option key={i} value={item?.ID}>
                  {item?.Type}
                </Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#1C75BC" }}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default TravelFormOne;
