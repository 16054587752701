import React from 'react'
import { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Accordion from 'react-bootstrap/Accordion';

function CorporateEthics() {
    return (
        <Fragment>
            <div className='container boxes'>
                <div className='row statment'>

                    <Fade down delay={500}>
                        <div className='col-sm-12'>
                            <h2>STATEMENT OF ETHICS & BUSINESS PRACTICES</h2>
                            <p>The Board of Directors has prepared this statement of ethics and business practices to establish a standard of conduct for employees and directors of Salaam Takaful Limited ‘the Company’, to be employed for conduct of its business and the business of the Participants’ Takaful Fund under its management. Each director and employee signs the statement in acknowledgement of his/her understanding and acceptance of the standard of conduct.</p>

                            <Accordion defaultActiveKey="3">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Statement of Ethics</Accordion.Header>
                                    <Accordion.Body>
                                        <div data-aos="fade-left" data-aos-duration="4000">
                                            <p>The Directors and Employees of the Company shall:</p>
                                            <ul>
                                                <li>Act with integrity, competence and dignity when dealing with the public, participants, prospects, employers, directors and peers.</li>
                                                <li>Practice and encourage others to practice in a professional and ethical manner.</li>
                                                <li>Strive to maintain and improve their competence and the competence of others in the profession.</li>
                                                <li>Use reasonable care and exercise independent professional judgement.</li>
                                                <li>Refrain from divulging Company’s sensitive information to outsiders.</li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Statement of Business Practices</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div data-aos="fade-left" data-aos-duration="4000">
                                                    <p>Salaam Takaful Limited's Directors and employees shall:</p>
                                                    <ul>
                                                        <li>Act with integrity, competence and dignity when dealing with the public, participants, prospects, employers, directors and peers.</li>
                                                        <li>Practice and encourage others to practice in a professional and ethical manner</li>
                                                        <li>Strive to maintain and improve their competence and the competence of others in the profession.</li>
                                                        <li>Use reasonable care and exercise independent professional judgement.</li>
                                                        <li>Refrain from divulging Company’s sensitive information to outsiders.</li>
                                                        <li>Encouraging or permitting persons associated with them to offer, promise or pay bribes or receive bribes. Bribes include payments of cash, gifts, entertainment or anything of value, or an inducement of any kind.</li>
                                                        <li>Take any cash or near-to-cash benefit from any stake holder of the Company to provide undue advantage.</li>
                                                        <li>Pay any cash or near-to-cash benefit to any stake holder of the Company to take undue advantage.</li>
                                                        <li>ABC i.e. making or receiving of bribes or corrupt payments in any form and will uphold all laws relevant to countering bribery and corruption in all the jurisdictions in which we operate.</li>
                                                        <li>Directors and employees may offer or accept only such gifts, entertainment and hospitality that are inconsequential.</li>
                                                    </ul>
                                                </div>

                                                <div data-aos="fade-left" data-aos-duration="4000">
                                                    <p>Fundamental responsibilities:</p>
                                                    <ul>
                                                        <li>The directors & employees shall maintain knowledge of and comply with all the applicable laws, rules and regulations governing the Company;</li>
                                                        <li>Not knowingly participate or assist in any violation of such laws, rules or regulations</li>
                                                    </ul>
                                                </div>

                                                <div data-aos="fade-left" data-aos-duration="4000">
                                                    <p>Relationship with and Responsibilities to the Company.</p>
                                                    <ul>
                                                        <li>Not undertake any independent practice that could result in compensation or other benefit in competition with their Company unless they obtain written consent from both Company and the person or entities from which they undertake independent practice.</li>
                                                        <li>Disclose to the Company all matters, including beneficial ownership of securities or other investments that could reasonably be expected to interfere with their duty to the Company or ability to make unbiased and objective recommendations.</li>
                                                        {/* <li>Comply with prohibitions or activities imposed by the Company if a conflict of interest exists.</li>
                                                        <li>Exercise reasonable supervision over those subject to their supervision or authority to prevent any violation of applicable statutes or regulations.</li>
                                                        <li>Ensure proper flow of adequate information.</li> */}
                                                    </ul>
                                                </div>


                                            </div>
                                            <div className='col-sm-6'>
                                                <div data-aos="fade-left" data-aos-duration="4000">
                                                    <ul>
                                                        <li>Comply with prohibitions or activities imposed by the Company if a conflict of interest exists.</li>
                                                        <li>Exercise reasonable supervision over those subject to their supervision or authority to prevent any violation of applicable statutes or regulations.</li>
                                                        <li>Ensure proper flow of adequate information.</li>
                                                    </ul>
                                                </div>
                                                <div data-aos="fade-right" data-aos-duration="4000">
                                                    <p>Relationship with and Responsibilities to Clients and Prospects:</p>
                                                    <ul>
                                                        <li>Exercise diligence and thoroughness in the performance of their duties, recommendations or in taking actions.</li>
                                                        <li>Have a reasonable and adequate basis, supported by appropriate research and investigation, for such recommendations or actions.</li>
                                                        <li>Make reasonable or diligent efforts to avoid any material misrepresentation.</li>
                                                        <li>Maintain appropriate records to support the reasonableness of such recommendation or action.</li>
                                                        <li>Use reasonable care and judgment to achieve and maintain independence and objectivity in the performance of their job responsibilities.</li>
                                                        <li>Must act for the benefit of the participants and place participants’ interests before their own.</li>
                                                        <li>Preserve the confidentiality of information communicated by their clients, prospect or employers concerning matters within the scope of client-employee or employer-employee relationships unless the employee receives information concerning illegal activities on the part of the client, prospect or employer.</li>
                                                        <li>Not make any statement, orally or in writing, that misrepresent:</li>
                                                        <li>The services that they or the Company is capable of performing</li>
                                                        <li>Their qualifications or the qualifications of the Company</li>
                                                        <li>The academic or professional credentials</li>
                                                        <li>Any assurance or guarantees regarding any Takaful coverage except to communicate accurate information regarding the terms of the policy instrument and the issuer’s obligations under the said Takaful policy.</li>
                                                        <li>Not make any statement, orally or in writing, that misrepresent the performance that they or their Company has accomplished or can reasonably be expected to achieve.</li>
                                                        <li>Not communicate individual or Company performance information directly or indirectly to the participants, or prospective participants or in a manner intended to be received by participants or prospective participants and shall make reasonable effort to ensure that such information is fair, accurate and a complete presentation of such performance.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Fade>



                </div>
            </div>
        </Fragment>
    )
}

export default CorporateEthics