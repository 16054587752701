import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { getTravelRelation } from "../../../Redux/actions/getTravelRelation";
import { useDispatch, useSelector } from "react-redux";

const TravelFormFive = ({ onFinishStep5, onFinishFailedFive, handlePrev, form }) => {
  const dispatch = useDispatch();

  const relationData = useSelector((state) => state.getTravelRelation.data);

  useEffect(() => {
    dispatch(getTravelRelation());
  }, []);
  return (
    <div
      className="col-sm-8 mForm"
      style={{ backgroundColor: "rgb(30 123 95 / 69%)" }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Beneficiary Information (Next Of Kin) </h5>
        <Form
          name="sehatFormFive"
          onFinish={onFinishStep5}
          onFinishFailed={onFinishFailedFive}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="BENEFICIARY_NAME"
                label="Benfeficiary Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Beneficiary Name",
                  },
                ]}
              >
                <Input placeholder="Beneficiary Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="BENEFICIARY_RELATION"
                label="Beneficiary Relation"
                rules={[
                  {
                    required: true,
                    message: "Please Select Beneficiary Relation",
                  },
                ]}
              >
                <Select
                  placeholder="Beneficiary Relation"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {relationData?.map((item, i) => (
                    <Select.Option key={i} value={item.ID}>
                      {item.Relation}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="BENEFICIARY_CNIC"
                label="Beneficiary CNIC"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                    message: "Enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 5) {
                    return onlyNums.slice(0, 5);
                  }
                  if (onlyNums.length <= 12) {
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                  }
                  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                    5,
                    12
                  )}-${onlyNums.slice(12, 13)}`;
                }}
              >
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="BENEFICIARY_CONTACT_NO"
                label="Beneficiary Contact Number"
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message:
                      "Enter a valid contact number 03XX-XXXXXXX format",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>

            <div className="col-sm-6 baaccck">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Next
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  );
};

export default TravelFormFive;
