import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMotorCity } from "../../../Redux/actions/getMotorCity";
import { getMotorCC } from "../../../Redux/actions/getMotorCC";
import { getMotorColor } from "../../../Redux/actions/getMotorColor";

const ComercialForm = ({
  onFinishFailedCommercial,
  onFinishStepCommercial,
  form,
  handlePrev
}) => {
  const { data } = useSelector((state) => state.getMotorCity);
  const ccData = useSelector((state) => state.getMotorCC.data);
  const colorData = useSelector((state) => state.getMotorColor.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMotorCity());
    dispatch(getMotorCC());
    dispatch(getMotorColor());
  }, [dispatch]);

  return (
    <div
      className="col-sm-8 mForm"
      style={{
        backgroundColor: "rgb(30 123 95 / 69%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Motor Takaful</h3>
        <h5 className="cnhbsh5">Commercial Vehicle</h5>
        <Form
          name="motorFormThree"
          onFinish={onFinishStepCommercial}
          onFinishFailed={onFinishFailedCommercial}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CompanyName"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Company Name",
                  },
                ]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CName"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!"
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>

            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CEmail"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email is required!"
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CContactNo"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message: "Contact number must be in this format 03XX-XXXXXXX",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item
                name="CRemarts"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Remarks is required!"
                  },
                ]}
              >
                <Input placeholder="Remarks" />
              </Form.Item>
            </div>

            <div className="col-sm-6 baaccck">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Submit
                </Button>
              </Form.Item>

            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ComercialForm;
