import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    message: null,
    error: null,
    data: [],
};

const getPetTitlesSlice = createSlice({
    name: "getPetTitles",
    initialState,
    reducers: {
        getPetTitlesStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.message = null;
        },

        getPetTitlesSuccess(state, action) {
            state.loading = false;
            state.data = action?.payload;
            state.message = action.payload.message;
        },
        getPetTitlesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.message = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearMessage: (state) => {
            state.message = null;
        },
    },
});

export const {
    getPetTitlesStart,
    getPetTitlesSuccess,
    getPetTitlesFailure,
    clearError,
    clearMessage,
} = getPetTitlesSlice.actions;

export default getPetTitlesSlice.reducer;
