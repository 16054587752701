import React, { Fragment } from "react";
import Tab from "react-bootstrap/Tab";
import { Esgtabss } from "../../Data/Data";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import unsdg from '../../Assets/UNSDG.jpg'
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

function Esgtabs() {
  const handleSlideClick = (swiper) => {
    const activeIndex = swiper.clickedIndex;
    swiper.slideTo(activeIndex);
  };

  return (
    <Fragment>
      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Row className="newEsg">
          <Col sm={12}>
            <img src={unsdg} alt='asd' className='unsdgImg'/>
          </Col>
          <Col sm={12} className="newEsgTwelve">
            <Nav variant="pills" className="">
              <Swiper
                slidesPerView={4}
                pagination={{ clickable: true }}
                spaceBetween={20}
                updateOnWindowResize={true}
                loop={true}
                navigation={{ clickable: true }}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                  swiper.on("click", handleSlideClick);
                }}
                breakpoints={{
                  "@0.00": { slidesPerView: 1, spaceBetween: 10 },
                  575: { slidesPerView: 2, spaceBetween: 30 },
                  820: { slidesPerView: 3, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {Array.from({ length: 100 }).map((_, index) => {
                  const item = Esgtabss[index % Esgtabss.length];
                  return (
                    <SwiperSlide>
                      <Nav.Item>
                        <Fade down delay={500}>
                          <Nav.Link eventKey={item.key}>
                            <img src={item.image} alt="tabs" />
                            <h4>{item.mainheading}</h4>
                          </Nav.Link>
                        </Fade>
                      </Nav.Item>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Nav>
          </Col>

          <Col sm={12}>
            <Tab.Content>
              {Esgtabss.map((item) => {
                return (
                  <Tab.Pane eventKey={item.key}>
                    <div
                      className="row tabs"
                      style={{ backgroundImage: `url(${item.image})` }}
                    >
                      <div className="col-sm-4"></div>
                      <div className="col-sm-6">
                        <div className="mycontents">
                          <div
                            className="row"
                            data-aos="fade-right"
                            data-aos-duration="4000"
                            data-aos-easing="ease-out-cubic"
                          >
                            <div className="col-sm-12">
                              <Fade up delay={500}>
                                {" "}
                                <p
                                  data-aos="fade-right"
                                  data-aos-duration="4000"
                                  data-aos-easing="ease-out-cubic"
                                >
                                  {item.content}
                                </p>
                              </Fade>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Fragment>
  );
}

export default Esgtabs;
