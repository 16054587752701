import React, { useState, useEffect, Fragment } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './Style/laptop.css'
import './Style/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/responsive.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from './Components/Loader/Loader';
import Header from './Components/Header/Header';
import Home from './Layout/Home';
import Footer from './Components/Footer/Footer';
import Whoweare from './Layout/Whoweare';
import ShariahAdvisor from './Layout/ShariahAdvisor';
import WhistleBlowing from './Layout/WhistleBlowing';
import Governance from './Layout/Governance';
import BuyNow from './Layout/BuyNow';
import SideBar from './Layout/SideBar';
import Product from './Layout/Product';
import IndividualProduct from './Layout/IndividualProduct';
import CorporateProduct from './Layout/CorporateProduct';
import SalamMotor from './Layout/IndividualProducts/SalamMotor';
import SalamHome from './Layout/IndividualProducts/SalamHome';
import SalamPersonal from './Layout/IndividualProducts/SalamPersonal';
import SalamSehat from './Layout/IndividualProducts/SalamSehat';
import SalamMedical from './Layout/IndividualProducts/SalamMedical';
import SalamLiveStock from './Layout/IndividualProducts/SalamLiveStock';
import SalamCrop from './Layout/IndividualProducts/SalamCrop';
import SalaamMiscellaneous from './Layout/CorporateProduct/SalaamMiscellaneous';
import SalamEngineering from './Layout/CorporateProduct/SalamEngineering';
import SalamFire from './Layout/CorporateProduct/SalamFire';
import SalamHealth from './Layout/CorporateProduct/SalamHealth';
import FinancialProduct from './Layout/FinancialProduct';
import SalamBanker from './Layout/FinancialProduct/SalamBanker';
import SalamComputer from './Layout/FinancialProduct/SalamComputer';
import SalamDeposit from './Layout/FinancialProduct/SalamDeposit';
import SalamPlastic from './Layout/FinancialProduct/SalamPlastic';
import SalamProfessional from './Layout/FinancialProduct/SalamProfessional';
import SingleNewsPage from './Layout/SingleNewsPage';
import SalamTravel from './Layout/IndividualProducts/SalamTravel';
import SalamCyber from './Layout/FinancialProduct/SalamCyber';
import SalamMarine from './Layout/CorporateProduct/SalamMarine';
import Newsevent from "../src/Layout/Newsnevents"
import CorporateInformation from './Layout/CorporateInformation';
import Complain from './Layout/Complain';
import Surplus from './Layout/Surplus';
import SustainabilityESG from './Layout/SustainabilityESG';
import Contactus from './Layout/Contactus';
import Career from './Layout/Career';
import LifeAtSalaam from "./Layout/LifeAtSalaam";
import PerposalForm from "./Layout/Downloads/PerposalForm";
import Claimforms from "./Layout/Downloads/Claimforms";
import CompalintResolution from "./Layout/Downloads/CompalintResolution";
import Encahsed from "./Layout/Downloads/Encahsed";
import Policies from "./Layout/Downloads/Policies";
import Proxy from "./Layout/Downloads/Proxy";
import HospitalForm from "./Layout/Downloads/HospitalForm";
import Discount from "./Layout/Downloads/Discount";
import Mcollateral from "./Layout/Downloads/Mcollateral";
import FinancialReports from "./Layout/Downloads/FinancialReports";
import FinancialHighlight from "./Layout/Downloads/FinancialHighlight";
import NOGM from "./Layout/Downloads/NOGM";
import Announcement from "./Layout/Announcement";
import SingleAnnouncement from "./Components/Announcement/SingleAnnouncement";
import Awards from "./Layout/Awards";
import SingleAwards from "./Components/Awards/SingleAwards";
import PrivacyPolicy from "./Layout/PrivacyPolicy";
import Annual from "./Layout/Annual";
import SingleAnnualAwards from "./Components/Annual/SingleAnnualAwards";
import Aboutus from "./Layout/Aboutus";
import FAQ from "./Layout/FAQ";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import EVerify from './Layout/EVerify';
import ProductIndividual from './Layout/ProductIndividual';
import ProductCorporate from './Layout/ProductCorporate';
import ProductFinancial from './Layout/ProductFinancial';
import ClaimIntimation from './Layout/ClaimIntimation';
import { getToken } from "./Redux/actions/getToken";
import { useDispatch } from "react-redux";
import SalaamPet from './Layout/IndividualProducts/SalaamPet';

function App() {
  const dispatch = useDispatch();
  const [userIsMobile, setUserIsMobile] = useState(true);
  useEffect(() => {
    window.innerWidth < 520 ? setUserIsMobile(true) : setUserIsMobile(false);
  }, [userIsMobile]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <>
            <BrowserRouter>
              <Header />
              <Routes>
                <Route path="/" exact element={<Home />} />

                <Route path="/whoweare" exact element={<Whoweare />} />
                <Route
                  path="/company-details"
                  exact
                  element={<CorporateInformation />}
                />
                <Route path="/compain-box" exact element={<Complain />} />
                <Route
                  path="/surplus-distribution"
                  exact
                  element={<Surplus />}
                />
                <Route path="/esg" exact element={<SustainabilityESG />} />
                <Route
                  path="/whistle-blowing"
                  exact
                  element={<WhistleBlowing />}
                />
                <Route path="/faq" exact element={<FAQ />} />
                <Route path="/contactus" exact element={<Contactus />} />
                <Route path="/career" exact element={<Career />} />
                <Route path="/shariah" exact element={<ShariahAdvisor />} />
                <Route path="/governance" exact element={<Governance />} />
                <Route path="/about-us" exact element={<Aboutus />} />
                <Route
                  path="/life-at-salaam"
                  exact
                  element={<LifeAtSalaam />}
                />

                <Route path="/buynow" exact element={<BuyNow />} />
                <Route path="/sidebar" exact element={<SideBar />} />
                {/* <Route path="/product" exact element={<Product />} /> */}
                <Route
                  path="/Individual-Product"
                  exact
                  element={<ProductIndividual />}
                />
                <Route
                  path="/Corporate-Product"
                  exact
                  element={<ProductCorporate />}
                />
                <Route
                  path="/Financial-Product"
                  exact
                  element={<ProductFinancial />}
                />
                <Route path="/e-verify" exact element={<EVerify />} />
                <Route path="/claim" exact element={<ClaimIntimation />} />
                <Route
                  path="/news-and-announcements"
                  exact
                  element={<Newsevent />}
                />
                <Route
                  path="/news-and-announcements/:Key"
                  exact
                  element={<SingleNewsPage />}
                />
                <Route
                  path="/articles-and-blogs"
                  exact
                  element={<Announcement />}
                />
                <Route
                  path="/articles-and-blogs/:Key"
                  exact
                  element={<SingleAnnouncement />}
                />
                <Route path="/annual-performance" exact element={<Awards />} />
                <Route
                  path="/annual-performance/:key"
                  exact
                  element={<SingleAwards />}
                />

                <Route
                  path="/awards-&-recognition"
                  exact
                  element={<Annual />}
                />
                <Route
                  path="/awards-&-recognition/:Key"
                  exact
                  element={<SingleAnnualAwards />}
                />

                <Route
                  path="/individualproduct"
                  exact
                  element={<IndividualProduct />}
                />
                <Route
                  path="/corporateproduct"
                  exact
                  element={<CorporateProduct />}
                />
                <Route
                  path="/financialproduct"
                  exact
                  element={<FinancialProduct />}
                />
                <Route
                  path="/individualproduct/salammotor"
                  exact
                  element={<SalamMotor />}
                />

                {/* <Route path='/individualproduct/salammotor' exact element={<SalamMotor />} /> */}
                <Route
                  path="/individualproduct/salamhome"
                  exact
                  element={<SalamHome />}
                />
                <Route
                  path="/individualproduct/salampersonal"
                  exact
                  element={<SalamPersonal />}
                />
                <Route
                  path="/individualproduct/salampet"
                  exact
                  element={<SalaamPet />}
                />
                <Route
                  path="/individualproduct/salamsehat"
                  exact
                  element={<SalamSehat />}
                />
                <Route
                  path="/individualproduct/salammedical"
                  exact
                  element={<SalamMedical />}
                />
                <Route
                  path="/corporateproduct/salamlivestock"
                  exact
                  element={<SalamLiveStock />}
                />
                <Route
                  path="/corporateproduct/salamcrop"
                  exact
                  element={<SalamCrop />}
                />
                <Route
                  path="/individualproduct/salamtravel"
                  exact
                  element={<SalamTravel />}
                />

                <Route
                  path="/corporateproduct/salam-miscellaneous"
                  exact
                  element={<SalaamMiscellaneous />}
                />
                <Route
                  path="/corporateproduct/salam-engineering"
                  exact
                  element={<SalamEngineering />}
                />
                <Route
                  path="/corporateproduct/salaam-group-health"
                  exact
                  element={<SalamHealth />}
                />
                <Route
                  path="/corporateproduct/salam-fire"
                  exact
                  element={<SalamFire />}
                />
                <Route
                  path="/corporateproduct/salam-marine"
                  exact
                  element={<SalamMarine />}
                />

                <Route
                  path="/financialproduct/salaam-banker"
                  exact
                  element={<SalamBanker />}
                />
                <Route
                  path="/financialproduct/salaam-professional"
                  exact
                  element={<SalamProfessional />}
                />
                <Route
                  path="/financialproduct/salaam-computer"
                  exact
                  element={<SalamComputer />}
                />
                <Route
                  path="/financialproduct/salaam-plasticcard"
                  exact
                  element={<SalamPlastic />}
                />
                <Route
                  path="/financialproduct/salaam-deposit"
                  exact
                  element={<SalamDeposit />}
                />
                <Route
                  path="/financialproduct/salaam-cyber"
                  exact
                  element={<SalamCyber />}
                />

                <Route path="/proposal-form" exact element={<PerposalForm />} />
                <Route path="/claim-form" exact element={<Claimforms />} />
                <Route
                  path="/complaint-resolution-form"
                  exact
                  element={<CompalintResolution />}
                />
                <Route path="/un-encahsed-form" exact element={<Encahsed />} />
                <Route path="/policies-form" exact element={<Policies />} />
                <Route path="/proxy-forms" exact element={<Proxy />} />
                <Route path="/hospital-form" exact element={<HospitalForm />} />
                <Route path="/discount-form" exact element={<Discount />} />
                <Route
                  path="/marketing-collateral-forms"
                  exact
                  element={<Mcollateral />}
                />

                <Route
                  path="/financial-reports"
                  exact
                  element={<FinancialReports />}
                />
                <Route
                  path="/financial-highlights"
                  exact
                  element={<FinancialHighlight />}
                />
                <Route path="/nogm" exact element={<NOGM />} />
                <Route
                  path="/privacy-policy"
                  exact
                  element={<PrivacyPolicy />}
                />
              </Routes>
              <Footer />
            </BrowserRouter>
          </>
        )}
      </Fragment>
    </React.Fragment>
  );
}

export default App;