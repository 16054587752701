import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    message: null,
    error: null,
    data: [],
};

const getPetBreedSlice = createSlice({
    name: "getPetBreed",
    initialState,
    reducers: {
        getPetBreedStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.message = null;
        },

        getPetBreedSuccess(state, action) {
            state.loading = false;
            state.data = action?.payload;
            state.message = action.payload.message;
        },
        getPetBreedFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.message = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearMessage: (state) => {
            state.message = null;
        },
    },
});

export const {
    getPetBreedStart,
    getPetBreedSuccess,
    getPetBreedFailure,
    clearError,
    clearMessage,
} = getPetBreedSlice.actions;

export default getPetBreedSlice.reducer;
