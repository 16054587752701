import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import FinProdNav from "./FinProdNav";
import Cybertabs from "./Cybertabs";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

function Salamcyber() {
  return (
    <Fragment>
      <FinProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner CyberRiskTakaful">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Cyber Risk Takaful</h2>
              <p className="cemter">
                <b>
                  {" "}
                  Cyber Takaful covers your business' liability for a data
                  breach involving sensitive customer information:
                </b>
                <br />
                Such as Social Security numbers, credit card numbers, account
                numbers, driver's license numbers and health records.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <Cybertabs />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Cyber Risk"} bgImage={`url(${process.env.REACT_APP_URL_ASSETS}Assets/NewBanner/CyberRisk.jpg)`} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Salamcyber;
