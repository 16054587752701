import React, { Fragment, useState } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { News } from "../../Data/Data";
import { Link } from "react-router-dom";

function Productbanner() {

  const [display, setDisplay] = useState(12);
  console.log(News.length)
  const handleViewMore = () => {
    if (display < News.length) {
      setDisplay((prevCount) => prevCount + 12);
    }
  }

  const handleViewLess = () => {
    if (display >= News.length || display > 12) {
      setDisplay((prevCount) => prevCount - 12);
    }
  }

  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row arrticleAndBlogs">
          <div className="col-sm-12 heads govbanner newss">
            <Fade up delay={500}>
              <h2 className="oswald"> News And Announcements</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text news">
            <div className="row ArticlesAndBlogs">
              {News.slice(0, display).map((data) => {
                const trimmedContent = data.content.split(" ").slice(0, 20).join(" ") + (data.content.split(" ").length > 20 ? "..." : "");
                return (
                  <div className="col-sm-4">
                    <Fade right delay={500}>
                      <Link
                        to={`/news-and-announcements/${data.Key}`}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <div className="menusildes" key={data.key}>
                          <Flip top delay={500}>
                            {" "}
                            <img src={data.feature} alt="" />
                          </Flip>
                          <Fade up delay={500}>
                            <p>{trimmedContent}</p>
                          </Fade>
                        </div>
                      </Link>
                    </Fade>
                  </div>
                );
              })}

              <div className="col-sm-12 viewMoreLess">
                {display < News.length && ( // Show "View More" only if there are more items to display
                  <button className="btn btn-primary" onClick={handleViewMore}>
                    View More
                  </button>
                )}
                {(display >= News.length || display > 12) && ( // Show "View More" only if there are more items to display
                  <button className="btn btn-primary" onClick={handleViewLess}>
                    View Less
                  </button>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

export default Productbanner;