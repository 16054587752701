import React, { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function PetTab() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row singleprod'>
                    <Fade down delay={500}>
                    <div className='col-sm-3 imgeboxes'>
                        <img src={`${process.env.REACT_APP_URL_ASSETS}individual/PetTakaful.jpg`} alt='' />
                        <h4>Pet Takaful</h4>
                    </div>
                    </Fade>

                    <Fade up delay={500}>
                    <div className='col-sm-12 mylist' style={{ backgroundImage: `url(${`${process.env.REACT_APP_URL_ASSETS}individual/PetTakaful1024.jpg`})` }}>
                        <div className='row'>
                            <div className='col-sm-4 givenPaddingLeft'>
                                {/* <h3>Comprehensive Pet Takaful Plans</h3> */}
                                <p>
                                    At Salaam Takaful Limited, we prioritize your pets’ health and safety. 
                                    Our plans are designed to provide financial support for veterinary expenses, accidental injuries, and even life protection.
                                </p>
                                {/* <ul className="LiveStockList givenPaddingLefts">
                                    <li><b></b></li> */}
                                    <h3>Plan Options: </h3>
                                    <ul className="LiveStockList givenPaddingLefts">
                                        <li>Coverage up to PKR 50,000: Annual Contribution PKR 5,000</li>
                                        <li>Coverage up to PKR 100,000: Annual Contribution PKR 7,000</li>
                                    </ul>
                                {/* </ul> */}
                            </div>

                            <div className='col-sm-4 peet'>
                                <h3>Coverages</h3>
                                <p className='fullWidth'>Our Pet Takaful covers a wide range of situations to ensure your pet's well-being:</p>
                                <ul className='LiveStockList'>
                                    <li>
                                        <b>Illness Coverage: </b>
                                        Veterinary fees for treatments, including alternative medicine, clinical diets, and behavioural therapies.
                                    </li>
                                    <li>
                                        <b>Accident & Injury Coverage: </b>
                                        Protection for accidental injuries, diagnostic tests, hospitalization, and follow-ups, including alternative therapies.
                                    </li>
                                    <li>
                                        <b>Theft & Straying: </b>
                                        Reimbursement for stolen or missing pets (over 30 days) and related veterinary expenses.
                                    </li>
                                    <li>
                                        <b>Life Protection: </b>
                                        Compensation for accidental or illness-related deaths post-policy initiation.
                                    </li>
                                </ul>
                                {/* <h3>Exclusions</h3>
                                <p className='fullWidth'>
                                    Pre-existing conditions, routine treatments, intentional harm, racing injuries, and specific incidents outside Pakistan. 
                                    Refer to the policy for detailed exclusions.
                                </p> */}
                            </div>

                            <div className='col-sm-4 peet'>
                                <h3>Exclusions</h3>
                                <p className='fullWidth'>
                                    Pre-existing conditions, routine treatments, intentional harm, racing injuries, and specific incidents outside Pakistan. 
                                    Refer to the policy for detailed exclusions.
                                </p>
                            </div>
                        </div>
                    </div>
                    </Fade>
                </div>
            </div>
        </Fragment>
    )
}

export default PetTab