import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import SalamPetTakaful from '../../Components/IndividualProductList/SalamPetTakaful'

function SalaamPet() {
    const metaData = {
        title: 'Salaam Pet Takaful',
        description: ''
    }
    return (
        <Fragment>
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
            </Helmet>
            <div className='container-fluid faderight '>
                <div className='row arrticleAndBlogs'>
                    <SalamPetTakaful />
                </div>
            </div>
        </Fragment>
    )
}

export default SalaamPet