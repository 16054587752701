import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getPetTitles } from "../../../Redux/actions/getPetTitles";
import { getPetTypess } from "../../../Redux/actions/getPetTypes";

const PetFormOne = ({ onFinishFailed, onFinishStep1, form }) => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const { data: petData } = useSelector((state) => state.getPetTitles);
    const { data: petTypes } = useSelector((state) => state.getPetTypes);

    console.log(petTypes, '<============pet_type_id')

    useEffect(() => {
        dispatch(getPetTitles());
        dispatch(getPetTypess())
    }, [dispatch]);

    useEffect(() => {
        if (petData && petData.length > 0) {
            form.setFieldsValue({ PRODUCT_ID: petData[0]?.ID });
        }
    }, [petData, form]);

    return (
        <div className="col-sm-8 mForm" style={{ backgroundColor: "rgb(30 123 95 / 69%)" }} >
            <div className="centreWidthFOrm">
                <h3 className="cnhbsh3">Salaam Pet Takaful</h3>
                <h5 className="cnhbsh5">Basic Information</h5>
                <Form
                    name="sehatFormOne"
                    onFinish={onFinishStep1}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                >
                    <div className="row">
                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="Title"
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select placeholder="Title">
                                    {petData[0]?.map((item, i) => (
                                        <Option value={item?.ID}>{item?.Title}</Option>
                                    ))}
                                    {/* <Option value="Mr">Mr</Option>
                                    <Option value="Mrs">Mrs</Option>
                                    <Option value="Miss">Miss</Option> */}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="FirstName"
                                label="First Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "First Name is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="LastName"
                                label="Last Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Last Name is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="DateOfBirth"
                                label="Date Of Birth"
                                rules={[
                                    {
                                        required: true,
                                        message: "Date Of Birth is required!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    placeholder="Date Of Birth"
                                    disabledDate={(current) => {
                                        return current && current > moment().startOf("day");
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="EmailAddress"
                                label="Email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Email is not valid",
                                    },
                                    {
                                        message: "Email is required!",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="MobileNumber"
                                label="Contact"
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^03\d{2}-\d{7}$/,
                                        message: "Contact number must be in this format 03XX-XXXXXXX",
                                    },
                                ]}
                                normalize={(value) => {
                                    if (!value) {
                                        return value;
                                    }
                                    const onlyNums = value.replace(/[^\d]/g, "");
                                    if (onlyNums.length <= 4) {
                                        return onlyNums;
                                    } else if (onlyNums.length <= 11) {
                                        return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                                    }
                                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                                }}
                            >
                                <Input placeholder="Contact 03XX-XXXXXXX" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="CNIC"
                                label="CNIC Number"
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                                        message: "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                                    },
                                ]}
                                normalize={(value) => {
                                    if (!value) {
                                        return value;
                                    }

                                    const onlyNums = value.replace(/[^\d]/g, "");
                                    if (onlyNums.length <= 5) {
                                        return onlyNums.slice(0, 5);
                                    }
                                    if (onlyNums.length <= 12) {
                                        return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                                    }
                                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                                        5,
                                        12
                                    )}-${onlyNums.slice(12, 13)}`;
                                }}
                            >
                                <Input placeholder="CNIC #" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="PostalAddress"
                                label="Postal Code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Postal Code is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Postal Code" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="Pet"
                                label="Select your pet "
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your pet!"
                                    },
                                ]}
                            >
                                <Select placeholder="Select your pet">
                                    {petTypes[0]?.map((item, i) => (
                                        <Option value={item?.pet_type_id}>{item?.pet_type}</Option>
                                    ))}
                                    {/* <Option value="Cat">Cat</Option>
                                    <Option value="Others">Others</Option> */}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ background: "#1C75BC" }}>
                                    Next
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default PetFormOne;