import React, { Fragment, useEffect } from 'react';
import { newJobArray } from '../../Data/Data';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Fade from "react-reveal/Fade";

function Careerjobsdetail({ data }) {
  useEffect(() => {
    console.log(data, "tabs");
  }, [data]);

  if (!newJobArray || !Array.isArray(newJobArray)) {
    return <div>No job details available.</div>;
  }

  const tabData = newJobArray.find((item) => item.key === data);

  if (!tabData) {
    return <div>Invalid data prop or job details not found.</div>;
  }

  const handleSlideClick = (swiper) => {
    const activeIndex = swiper.clickedIndex;
    swiper.slideTo(activeIndex);
  };

  return (
    <Fragment>
      <Tab.Container id="left-tabs-example" defaultActiveKey="1" className="careerjo">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="">
              <Swiper
                slidesPerView={1}
                pagination={{ clickable: true }}
                spaceBetween={20}
                updateOnWindowResize={true}
                loop={true}
                navigation={{ clickable: true }}
                modules={[Navigation]}
                onSwiper={(swiper) => { swiper.on('click', handleSlideClick); }}
                breakpoints={{
                  575: { slidesPerView: 2, spaceBetween: 10 },
                  1360: { slidesPerView: 3, spaceBetween: 30 }
                }}
                className="mySwiper slidesbyvcan"
              >
                {tabData.AvailablePositions.map((item) => (
                  <SwiperSlide key={item.key}>
                    <Nav.Item>
                      <Fade down delay={500}>
                        <Nav.Link eventKey={item.key}>
                          <h4>{item.positon}</h4>
                        </Nav.Link>
                      </Fade>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>
          </Col>

          <Col sm={12}>
            <Tab.Content>
              {tabData.AvailablePositions.map((item) => (
                <Tab.Pane key={item.key} eventKey={item.key}>
                  <div className='row tabs fullWidthInSwipe'>
                    <Fade up delay={500}>
                      {item?.Responsiblities?.length > 0 && (
                        <div className='col-sm-4 listJobs'>
                          <h3>Responsiblities</h3>
                          <ul>
                            {item?.Responsiblities?.map((req, i) => (
                              <li key={i}>{req}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {item?.Requirements?.length > 0 && (
                        <div className='col-sm-4 listJobs'>
                          <h3>Qualification & Requirements</h3>
                          <ul>
                            {item?.Requirements?.map((req, i) => (
                              <li key={i}>{req}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {item?.WeOffer?.length > 0 && (
                        <div className='col-sm-4 listJobs'>
                          <h3>What We Offer</h3>
                          <ul>
                            {item?.WeOffer?.map((req, i) => (
                              <li key={i}>{req}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                      <div className="col-sm-4 fullWidthInSwipe edu">
                        <a href={`mailto:${item?.applyNow}`}>Apply Now</a>
                      </div>
                    </Fade>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Fragment>
  );
}

export default Careerjobsdetail;