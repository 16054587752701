import { Form, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../Redux/actions/getToken";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postPAFormOneReducer";
import { clearMessage as clearTwoMessage } from "../../../Redux/reducers/postPAFormTwoReducer";
import { clearMessage as clearThreeMessage } from "../../../Redux/reducers/postPAFormThreeReducer";
import { postPAFormOne } from "../../../Redux/actions/postPAFormOne";
import { postPAFormTwo } from "../../../Redux/actions/postPAFormTwo";
import { postPAFormThree } from "../../../Redux/actions/postPAFormThree";
import moment from "moment/moment";
import PetFormOne from "./PetFormOne";
import PetFormTwo from "./PetFormTwo";
import PetFormThree from "./PetFormThree";

const MainForm = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [stepOneData, setStepOneData] = useState({});
    const [stepTwoData, setStepTwoData] = useState({});
    const [stepThreeData, setStepThreeData] = useState({});
    const [selectedPlan, setSelectedPlan] = useState();
    const [currentStep, setCurrentStep] = useState(1);
    const formOneData = useSelector((state) => state.PAFormOne.data);
    const formtwoData = useSelector((state) => state.PAFormTwo.data);
    const formOneError = useSelector((state) => state.PAFormOne.postError);
    const formTwoError = useSelector((state) => state.PAFormTwo.postError);
    const formThreeError = useSelector((state) => state.PAFormThree.postError);

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };


    const onFinishStep1 = async (values) => {
        try {
            setStepOneData(values);
            const resultAction = await dispatch(postPAFormOne({ values }));
            // setProduct_id(values.PRODUCT_ID);
            if (postPAFormOne.fulfilled.match(resultAction)) {
                form.resetFields();
                handleNext();
            } else {
                console.error("Action was not fulfilled:", resultAction);
            }
        } catch (error) {
            console.error("Error dispatching action:", error);
        }
    };

    const onFinishStep2 = async (values) => {
        setStepTwoData(values);
        const resultAction = await dispatch(postPAFormTwo({ selectedPlan }));
        if (postPAFormTwo.fulfilled.match(resultAction)) {
            form.resetFields();
            handleNext();
        }
    };
    console.log(currentStep);

    const onFinishStep3 = async (values) => {
        setStepThreeData(values);
        values.PLAN_ID = formtwoData.PlainID;
        values.Unique_ID = formOneData?.[0]?.Unique_ID;

        // Format for submission, but keep original Moment object for AntD
        const formattedValues = {
            ...values,
            DOB: moment(values.DOB).format("DD-MMM-YYYY"),
            CNIC_Expiry_Date: moment(values.CNIC_Expiry_Date).format("DD-MMM-YYYY"),
        };

        const resultAction = await dispatch(
            postPAFormThree({ values: formattedValues })
        );
        if (postPAFormThree.fulfilled.match(resultAction)) {
            setCurrentStep(1);
            form.resetFields();
            setIsModalVisible(true);
            setStepOneData({});
            setStepTwoData({});
            setStepThreeData({});
        }
    };

    // const onFinishStep4 = async (value) => {
    //     const values = {
    //         Affiliate_Code: value.Affiliate_Code ? value.Affiliate_Code : "",
    //         Dicount_Code: value.Dicount_Code ? value.Dicount_Code : "",
    //     };
    //     setStepFourData(values);
    //     values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;

    //     const resultAction = await dispatch(postPAFormFour({ values }));
    //     console.log(resultAction);
    //     if (postPAFormFour.fulfilled.match(resultAction)) {
    //         setCurrentStep(1);
    //         form.resetFields();
    //         setIsModalVisible(true);
    //         setStepOneData({});
    //         setStepTwoData({});
    //         setStepThreeData({});
    //         setStepFourData({});
    //     }
    // };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFinishFailedTwo = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFinishFailedThree = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    const handleModalOk = () => {
        setIsModalVisible(false);
    };
    useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
            dispatch(getToken());
        }
    }, [dispatch]);

    useEffect(() => {
        if (formOneError) {
            toast.error(formOneError);
            dispatch(clearMessage());
        }
        if (formTwoError) {
            toast.error(formTwoError);
            dispatch(clearTwoMessage());
        }
        if (formThreeError) {
            toast.error(formThreeError);
            dispatch(clearThreeMessage());
        }
    }, [formOneError, formTwoError, formThreeError]);

    useEffect(() => {
        if (currentStep === 1 && stepOneData) {
            form.setFieldsValue(stepOneData);
        }
        if (currentStep === 2 && stepTwoData) {
            form.setFieldsValue(stepTwoData);
        }
        if (currentStep === 3 && stepThreeData) {
            form.setFieldsValue(stepThreeData);
        }
    }, [currentStep, form, stepOneData, stepTwoData, stepThreeData]);
    return (
        <Fragment>
            <div className="container-fluid motorFomrOne">
                <div className="row formTakafulll" style={{ backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}individual/PetTakaful1920.jpg)`,
                        backgroundSize: "cover", backgroundRepeat: "no-repeat" }} >
                    {/* Form 1 */}

                    {currentStep === 1 && (
                        <PetFormOne onFinishStep1={onFinishStep1} onFinishFailed={onFinishFailed} form={form} />
                    )}

                    {/* Form 2 */}

                    {currentStep === 2 && (
                        <PetFormTwo
                            onFinishFailedTwo={onFinishFailedTwo}
                            onFinishStep2={onFinishStep2}
                            handlePrev={handlePrev}
                            form={form}
                        />
                    )}

                    {/* Form 3 */}

                    {currentStep === 3 && (
                        <PetFormThree
                            onFinishFailedThree={onFinishFailedThree}
                            onFinishStep3={onFinishStep3}
                            handlePrev={handlePrev}
                            form={form}
                        />
                    )}
                    <div className="col-sm-4 mForm"></div>
                </div>
            </div>
            <Modal
                title="Thank You!"
                open={isModalVisible}
                onOk={handleModalOk}
                centered
                footer={[
                    <button
                        key="ok"
                        onClick={handleModalOk}
                        className="btn btn-primary"
                        style={{ background: "#1C75BC" }}
                    >
                        OK
                    </button>
                ]}
            >
                <p style={{ fontSize: "17px" }}>One of our representatives will get in touch with you soon.</p>
            </Modal>
        </Fragment>
    );
};

export default MainForm;