import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import FinProdNav from "./FinProdNav";
import Plastictabs from "./Plastictabs";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamPlasticCard = () => {
  return (
    <Fragment>
      <FinProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner PlasticCard">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Plastic Card Takaful</h2>
              <p className="cemter">
                Covers against debits established due to unauthorized use of
                plastic cards.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <Plastictabs />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Plastic Card"} bgImage={`url(${process.env.REACT_APP_URL_ASSETS}Assets/NewBanner/PlasticCard.jpg)`} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamPlasticCard;
