import React from 'react'
import { Fragment } from 'react'
import { Fade } from 'react-reveal'
import { Link } from 'react-router-dom'

export default function Whotext() {
  return (
    <Fragment>
      <div className='row whoweare'>
        <div className='col-sm-6'>
          <p data-aos="fade-right" data-aos-duration="4000">Salaam Takaful Limited, is an Unlisted Public Limited Company incorporated in Pakistan on 2nd June 2006, under the Companies Ordinance, 1984 with a regulatory compliant capital, all fully paid-up.</p>
          <p data-aos="fade-right" data-aos-duration="4000"> The company has undergone a strategic transformation to better leverage the society at large with a mission that reflects a deep dedication to client success and relationships and with an innovative and global vision to serve larger markets. </p>
          <p data-aos="fade-right" data-aos-duration="4000"> Salaam Takaful Limited has the expertise and engagements with overseas Re-Takaful operators & assistance companies, enabling it to offer comprehensive coverage for large infrastructure projects, specialized risks, and umbrella/blanket covers especially tailored for Islamic banking operators as well as for large corporate groups. Our brand ethos has always been built on trust, credibility, and industry knowledge. </p>
          <p data-aos="fade-right" data-aos-duration="4000"> We pride ourselves on our relationships and our ability to help, support, and protect the community. We have the passion to do things differently and create value for all our business partners and stakeholders under the highest standards of Shariah guidelines.  </p>
          <p data-aos="fade-right" data-aos-duration="4000"> At Salaam Takaful Limited, we provide our clients with more than just exceptional Takaful coverages and risk management services. </p>
          <p data-aos="fade-right" data-aos-duration="4000" className='NOPBMB'> Our participants are the center of the universe for us and their comfort is our utmost priority. We are the trusted partners they turn to from time to time, to obtain exceptional value and attain 'Peace of mind.....Forever' </p>
        </div>

        <div className='col-sm-6'>
          <img src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/whofirst.jpg`} alt='image' data-aos="fade-left" data-aos-duration="4000" />
        </div>

        <div className="col-sm-6 centerBtttnnn" >
          <Fade bottom delay={4000}>
            <p className='slaamCorporate' >
              <Link to={`${process.env.REACT_APP_URL_ASSETS}Assets/pdf/SalaamTakafulLimitedCorporateProfile.pdf`} className=" buy" target="_blank">
                Salaam Takaful Corporate Profile
              </Link>
            </p>
          </Fade>
        </div>
      </div>
    </Fragment>
  )
}