import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import WhyChoseLivestock from "../WhychoseUs/WhyChoseLivestock";
import Livestocktab from "./Livestocktab";
import CorProdNav from "../CorporateProductList/CorProdNav";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

function SalamLiveStock() {
  return (
    <Fragment>
      <CorProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner livestock">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam LiveStock Takaful</h2>
              <p>
                Livestock is the farmers' investment into the future and means
                of earning, which can be wiped away with a single widespread
                disease, flooding & robbery along with other everyday risks. We,
                at Salaam Takaful Limited, protect the farmers in case of any
                unfortunate and unforeseen event (Death) relating to their
                livestock.
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Livestocktab />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Livestock"} bgImage={`url(${process.env.REACT_APP_URL_ASSETS}Assets/NewBanner/Livestock.jpg)`} />
          </div>
          <div className="col-sm-12 heads text">
            {/* <h2 data-aos="fade-down" data-aos-duration="4000">Coverage</h2> */}
            <WhyChoseLivestock />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamLiveStock;
