import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Whychosehome from "../WhychoseUs/Whychosehome";
import Productsnav from "./Productsnav";
import Hometab from "./Hometab";
import HomeForms from "./HomeForms/HomeForms";

function SalamHome() {
  return (
    <Fragment>
      <Productsnav />

      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner salamhome">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Home Takaful</h2>
              <p className="cemter">
                Are you looking to protect your precious home from unforeseen events?
              </p>
            </Fade>
            {/* <Flip top delay={1000}>
              <h3>Product Sub Heading</h3>
            </Flip> */}
          </div>

          <div className="col-sm-12 heads text">
            <Hometab />
          </div>
          <div className="col-sm-12 motorForm">
            <HomeForms />
          </div>
          <div className="col-sm-12 heads text">
            <Whychosehome />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamHome;
