import React from 'react'
import { Fragment } from 'react'
import Fade from "react-reveal/Fade";

function WhyChosePet() {
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  return (
    <Fragment>
      <div className='row whychosesalaam petTakafuls'>
        <div className='col-sm-12 chose'>
          <h2 data-aos="fade-top" data-aos-duration="4000">Why Choose Us?</h2>
        </div>

        <div className='col-sm-4 why' data-aos="fade-right" data-aos-duration="8000">
          <h3>Shariah-Compliant Coverage</h3>
          <p>Ethical and aligned with Islamic principles.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-down" data-aos-duration="8000">

          <h3>Fully Digital Process</h3>
          <p>Seamless online policy issuance and management through the Salaam App.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="8000">
          <h3>Simple Claim Procedure</h3>
          <p>Effortless claims lodging, monitoring, and resolution.</p>
        </div>

        <div className='col-sm-4 why' data-aos="fade-left" data-aos-duration="8000">
          <h3>Exclusive Benefits</h3>
          <p>Discounts at diagnostic centers, pharmacies, and a buy-one-get-one offer at 1,200 merchants across Pakistan.</p>
        </div>
      </div>




      <Fade up delay={500}>
        <div className='row claimprocedure personalAccident petTakafuls'>
          <div className='col-sm-12'>
            <Fade left delay={700}><h2>How to Claim?</h2></Fade>
          <p className='Centewerr'>Filing a claim is simple</p>
            <ul>
              <Fade up delay={800}><li><img src={Check} alt='Check' />Submit the claim form via the Salaam App or website</li></Fade>
              <Fade up delay={900}> <li><img src={Check} alt='Check' />Provide the required documents for verification.</li></Fade>
              <Fade up delay={1000}> <li><img src={Check} alt='Check' />Receive reimbursement for eligible expenses promptly.</li></Fade>
            </ul>
          </div>
        </div>
      </Fade>
    </Fragment>
  )
}

export default WhyChosePet

