import { Form, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../Redux/actions/getToken";
import Cookies from "js-cookie";
import { postHomeOne } from "../../../Redux/actions/postHomeOne";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postHomeOneReducer";
import { clearMessage as clearTwoMessage } from "../../../Redux/reducers/postHomeFormTwoReducer";
import { clearMessage as clearThreeMessage } from "../../../Redux/reducers/postHomeFormThreeReducer";
import { clearMessage as clearFourMessage } from "../../../Redux/reducers/postHomeFormFourReducer";
import { clearMessage as clearFiveMessage } from "../../../Redux/reducers/postHomeFormFiveReducer";
import HomeFormOne from "./HomeFormOne";
import HomeFormTwo from "./HomeFormTwo";
import { postHomeFormTwo } from "../../../Redux/actions/postHomeFormTwo";
import { postHomeFormFive } from "../../../Redux/actions/postHomeFormFive";
import HomeFormThree from "./HomeFormThree";
import { postHomeFormThree } from "../../../Redux/actions/postHomeFormThree";
import HomeFormFour from "./HomeFormFour";
import { postHomeFormFour } from "../../../Redux/actions/postHomeFormFour";
import HomeFormFive from "./HomeFormFive";
import moment from "moment";

const HomeForms = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepThreeData, setStepThreeData] = useState({});
  const [stepFourData, setStepFourData] = useState({});

  const [selectedHMIDs, setSelectedHMIDs] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();

  const [currentStep, setCurrentStep] = useState(1);
  const [product_id, setProduct_id] = useState(4);
  const [DOB, setDOB] = useState("12-Jun-1984");

  const formOneData = useSelector((state) => state.postHomeOne.data);
  const formthreeData = useSelector((state) => state.postHomeFormThree.data);
  const formOneError = useSelector((state) => state.postHomeOne.postError);
  const formTwoError = useSelector((state) => state.postHomeFormTwo.postError);
  const formThreeError = useSelector(
    (state) => state.postHomeFormThree.postError
  );

  const formFourError = useSelector(
    (state) => state.postHomeFormFour.postError
  );
  const formFiveError = useSelector(
    (state) => state.postHomeFormFive.postError
  );
  const formOnePostedData = useSelector(
    (state) => state.postHealthOne.postData
  );

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishStep1 = async (values) => {
    console.log("Step 1 values:", values);
    setStepOneData(values);

    values.ProductID = "1";
    const resultAction = await dispatch(postHomeOne({ values }));
    if (postHomeOne.fulfilled.match(resultAction)) {
      form.resetFields();
      handleNext();
    }
  };

  const onFinishStep2 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;
    setStepTwoData(values);

    const resultAction = await dispatch(postHomeFormTwo({ values }));
    if (postHomeFormTwo.fulfilled.match(resultAction)) {
      form.resetFields();
      handleNext();
    }
  };

  const onFinishStep3 = async (values) => {
    console.log(values);
    setStepThreeData(values);

    const resultAction = await dispatch(postHomeFormThree({ values }));
    if (postHomeFormThree.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep4 = async (values) => {
    values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;
    values.PlanID = formthreeData && formthreeData?.PlainID;
    values.ProductID = "1";
    setStepFourData(values);
    // values.DOB = moment(values.DOB).format("DD-MMM-YYYY");
    // values.CNICEXP = moment(values.CNICEXP).format("DD-MMM-YYYY");

    const formattedValues = {
      ...values,
      DOB: moment(values.DOB).format("DD-MMM-YYYY"),
      CNICEXP: moment(values.CNICEXP).format("DD-MMM-YYYY"),
    };

    const resultAction = await dispatch(
      postHomeFormFour({ values: formattedValues })
    );
    if (postHomeFormFour.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep5 = async (values) => {
    console.log("Values:", values);

    values.Unique_ID = formOneData && formOneData[0]?.Unique_ID;
    values.Affiliate_Code = values.Affiliate_Code ? values.Affiliate_Code : "";
    values.Dicount_Code = values.Dicount_Code ? values.Dicount_Code : "";

    const resultAction = await dispatch(postHomeFormFive({ values }));
    if (postHomeFormFive.fulfilled.match(resultAction)) {
      // toast.success("Form Submitted Successfully");

      setCurrentStep(1);
      form.resetFields();
      setIsModalVisible(true);
      setStepOneData({});
      setStepTwoData({});
      setStepThreeData({});
      setStepFourData({});
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedTwo = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedThree = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFour = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFive = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (formOneError) {
      toast.error(formOneError);
      dispatch(clearMessage());
    }
    if (formTwoError) {
      toast.error(formTwoError);
      dispatch(clearTwoMessage());
    }
    if (formThreeError) {
      toast.error(formThreeError);
      dispatch(clearThreeMessage());
    }
    if (formFourError) {
      toast.error(formFourError);
      dispatch(clearFourMessage());
    }
    if (formFiveError) {
      toast.error(formFiveError);
      dispatch(clearFiveMessage());
    }
  }, [
    formOneError,
    formTwoError,
    formThreeError,
    formFourError,
    formFiveError,
  ]);
  console.log(stepFourData, "<==========stepFourData");
  useEffect(() => {
    if (currentStep === 1 && stepOneData) {
      form.setFieldsValue(stepOneData);
    }
    if (currentStep === 2 && stepTwoData) {
      form.setFieldsValue(stepTwoData);
    }
    if (currentStep === 3 && stepThreeData) {
      form.setFieldsValue(stepThreeData);
    }
    if (currentStep === 4 && stepFourData) {
      form.setFieldsValue(stepFourData);
    }
  }, [
    currentStep,
    form,
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
  ]);
  return (
    <Fragment>
      <div className="container-fluid motorFomrOne">
        <div
          className="row formTakafulll"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/NewBanner/Home.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Form 1 */}

          {currentStep === 1 && (
            <HomeFormOne
              onFinishStep1={onFinishStep1}
              onFinishFailed={onFinishFailed}
              form={form}
              handlePrev={handlePrev}
            />
          )}

          {/* Form 2 */}

          {currentStep === 2 && (
            <HomeFormTwo
              onFinishFailedTwo={onFinishFailedTwo}
              onFinishStep2={onFinishStep2}
              handlePrev={handlePrev}
              form={form}
            />
          )}

          {/* Form 3 */}

          {currentStep === 3 && (
            <HomeFormThree
              onFinishFailedThree={onFinishFailedThree}
              onFinishStep3={onFinishStep3}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              handlePrev={handlePrev}
            />
          )}

          {/* Form 4 */}

          {currentStep === 4 && (
            <HomeFormFour
              formOnePostedData={formOnePostedData}
              onFinishFailedFour={onFinishFailedFour}
              onFinishStep4={onFinishStep4}
              selectedHMIDs={selectedHMIDs}
              setSelectedHMIDs={setSelectedHMIDs}
              handlePrev={handlePrev}
              form={form}
            />
          )}

          {/* Form 5 */}

          {currentStep === 5 && (
            <>
              <HomeFormFive
                onFinishStep5={onFinishStep5}
                onFinishFailedFive={onFinishFailedFive}
                DOB={DOB}
                product_id={product_id}
                handlePrev={handlePrev}
              />
            </>
          )}

          <div className="col-sm-4 mForm">
          </div>
        </div>
      </div>
      <Modal
        title="Thank You!"
        open={isModalVisible}
        onOk={handleModalOk}
        centered
        footer={[
          <button
            key="ok"
            onClick={handleModalOk}
            className="btn btn-primary"
            style={{ background: "#1C75BC" }}
          >
            OK
          </button>,
        ]}
      >
        <p style={{ fontSize: "17px" }}>
          One of our representatives will get in touch with you soon.
        </p>
      </Modal>
    </Fragment>
  );
};

export default HomeForms;
