import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    message: null,
    error: null,
    data: [],
};

const getPetAncestrySlice = createSlice({
    name: "getPetAncestry",
    initialState,
    reducers: {
        getPetAncestryStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.message = null;
        },

        getPetAncestrySuccess(state, action) {
            state.loading = false;
            state.data = action?.payload;
            state.message = action.payload.message;
        },
        getPetAncestryFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.message = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearMessage: (state) => {
            state.message = null;
        },
    },
});

export const {
    getPetAncestryStart,
    getPetAncestrySuccess,
    getPetAncestryFailure,
    clearError,
    clearMessage,
} = getPetAncestrySlice.actions;

export default getPetAncestrySlice.reducer;
