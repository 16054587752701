import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { getHomeCity } from "../../../Redux/actions/getHomeCity";
import { getHomeOccupation } from "../../../Redux/actions/getHomeOccupation";

const HomeFormFive = ({ onFinishStep5, onFinishFailedFive, handlePrev }) => {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getHomeCity());
    dispatch(getHomeOccupation());
  }, [dispatch]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{ backgroundColor: "rgb(30 123 95 / 69%)" }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Home Takaful</h3>
        <h5 className="cnhbsh5"> Checkout Form</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep5}
          onFinishFailed={onFinishFailedFive}
          autoComplete="off"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Dicount_Code" label="Discount Code">
                <Input placeholder="Dicount Code" />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Affiliate_Code" label="Affiliate Code">
                <Input placeholder="Affliate Code" />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Button onClick={handlePrev}>Back</Button>
            </div>
            <div className="col-sm-6">
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1C75BC" }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormFive;
