import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    message: null,
    error: null,
    data: [],
};

const getVaccinationStatusSlice = createSlice({
    name: "getVaccinationStatus",
    initialState,
    reducers: {
        getVaccinationStatusStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.message = null;
        },

        getVaccinationStatusSuccess(state, action) {
            state.loading = false;
            state.data = action?.payload;
            state.message = action.payload.message;
        },
        getVaccinationStatusFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.message = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearMessage: (state) => {
            state.message = null;
        },
    },
});

export const {
    getVaccinationStatusStart,
    getVaccinationStatusSuccess,
    getVaccinationStatusFailure,
    clearError,
    clearMessage,
} = getVaccinationStatusSlice.actions;

export default getVaccinationStatusSlice.reducer;
