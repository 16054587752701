import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select, Upload } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getPetBreed } from "../../../Redux/actions/getPetBreed";
import { getPetAncestry } from "../../../Redux/actions/getPetAncestry";

const PetFormTwo = ({ onFinishFailedTwo, onFinishStep2, form, handlePrev }) => {
    const { Option } = Select;

    const dispatch = useDispatch();
    const { data: petBreedData } = useSelector((state) => state.getPetBreed);
    const { data: PetAncestry } = useSelector((state) => state.getPetAncestry);

    useEffect(() => {
        dispatch(getPetBreed(1));
        dispatch(getPetAncestry())
    }, [dispatch]);

    console.log(PetAncestry, '<============pet_type_id')

    useEffect(() => {
        if (petBreedData && petBreedData.length > 0) {
            form.setFieldsValue({ PRODUCT_ID: petBreedData[0]?.ID });
        }
    }, [petBreedData, form]);
    return (
        <div className="col-sm-8 mForm" style={{ backgroundColor: "rgb(30 123 95 / 69%)" }} >
            <div className="centreWidthFOrm">
                <h3 className="cnhbsh3">Salaam Pet Takaful</h3>
                <h5 className="cnhbsh5">Pet Details</h5>
                <Form
                    name="sehatFormOne"
                    onFinish={onFinishStep2}
                    onFinishFailed={onFinishFailedTwo}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                >
                    <div className="row">
                        {/* <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="Pet"
                                label="Select your pet "
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your pet!"
                                    },
                                ]}
                            >
                                <Select placeholder="Select your pet">
                                    <Option value="Cat">Cat</Option>
                                    <Option value="Others">Others</Option>
                                </Select>
                            </Form.Item>
                        </div> */}

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="Pet"
                                label="Select your pet "
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select your pet!"
                                    },
                                ]}
                            >
                                <Select placeholder="Select your pet">
                                    <Option value="Cat">Cat</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="PET_NAME"
                                label="Pet Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Pet Name is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Pet Name" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="ANCESTRY"
                                label="Ancestry "
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Ancestry!"
                                    },
                                ]}
                            >
                                <Select placeholder="Ancestry">
                                    {PetAncestry[0]?.map((item, i) => (
                                        <Option value={item?.ID} key={i}>{item?.Ancestry}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="PET_GENDER"
                                label="Pet Gender"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Pet Gender!"
                                    },
                                ]}
                            >
                                <Select placeholder="Pet Gender">
                                    <Option value="M">Male</Option>
                                    <Option value="F">Female</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {/* <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="LastName"
                                label="Last Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Last Name is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                        </div> */}

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="PET_BORN"
                                label="Pet Date Of Birth"
                                rules={[
                                    {
                                        required: true,
                                        message: "Pet Date Of Birth is required!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    placeholder="Pet Date Of Birth"
                                    disabledDate={(current) => {
                                        return current && current > moment().startOf("day");
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="BIG_PET"
                                label="How big is your pet"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Pet Size!"
                                    },
                                ]}
                            >
                                <Select placeholder="How big is your pet">
                                    <Option value="1 - 15 kgs">1 - 15 kgs</Option>
                                    <Option value="15 - 30 kgs ">15 - 30 kgs </Option>
                                    <Option value="30+ kgs ">30+ kgs </Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="SIGNS_OF_ILLNESS"
                                label="Signs Of Illness"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Signs Of Illness!"
                                    },
                                ]}
                            >
                                <Select placeholder="Signs Of Illness">
                                    <Option value="Has always been healthy ">Has always been healthy </Option>
                                    <Option value="Has chronic medical condition">Has chronic medical condition</Option>
                                    <Option value="Has chronic medical conditions but now is healthy">Has chronic medical conditions but now is healthy</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="BREED_TYPE_ID"
                                label="Pet Breed"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Pet Breed"
                                    showSearch
                                    filterOption={(inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
                                >
                                    {/* {catBreeds.map((item, i) => (
                                        <Option value={item.id} key={i}>{item.name}</Option>
                                    ))} */}
                                    {petBreedData[0]?.map((item, i) => (
                                        <Option value={item?.breed_type_id}>{item?.breed_type}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="PAY_PET"
                                label="Pet Pay"
                                rules={[
                                    {
                                        message: "Pet Pay is required!",
                                        required: true,
                                    }
                                ]}
                            >
                                <Input placeholder="How much did you pay for your pet?" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="COVER_TO_START"
                                label="When would you like cover to start?"
                                rules={[
                                    {
                                        required: true,
                                        message: "Cover to start is required!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    placeholder="When would you like cover to start?"
                                    disabledDate={(current) => {
                                        return current && current < moment().startOf("day");
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12 labelSSS">
                            <Form.Item
                                name="Images"
                                label="Upload Images"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                    return e?.fileList || [];
                                }}
                            >
                                <Upload
                                    action="/upload.do"
                                    beforeUpload={() => false}
                                    accept="image/png, image/jpeg, image/webp"
                                    // defaultFileList={fileList}
                                    // name="logo"
                                    maxCount={15}
                                    multiple={true}
                                    listType="picture-card"
                                >
                                    Add Pictures
                                </Upload>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6">
                            <Form.Item>
                                <Button onClick={handlePrev}>Back</Button>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ background: "#1C75BC" }}>
                                    Next
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default PetFormTwo;
