import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade';
import Capitals from './Capitals';
import Directorbox from './Directorbox';

function Govbanner() {
  return (
    <Fragment>
      <div className='container governancemain motor' >
        <div className='row arrticleAndBlogs'>
          <div className='col-sm-12 heads govbanner governance'>
            <Fade up delay={500}><h2 className="oswald">Governance</h2></Fade>
          </div>
          <div className='col-sm-12 heads text govdirectors'>
            <Directorbox />
          </div>
          {/* <div className='col-sm-12 heads text mymaxheight'></div> */}
          <div className='col-sm-12 heads text shareholder'>
            <Capitals />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Govbanner