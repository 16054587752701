import { createAsyncThunk } from "@reduxjs/toolkit";
import {getVaccinationStatusStart, getVaccinationStatusSuccess, getVaccinationStatusFailure
} from "../reducers/getVaccinationStatusReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const getVaccinationStatus = createAsyncThunk(
    "getVaccinationStatus",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(getVaccinationStatusStart());
            const token = Cookies.get("token");

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/PetInsurance/GetVaccinationStatus`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const responseData = await response;

            thunkAPI.dispatch(getVaccinationStatusSuccess(responseData?.data?.result));

            return responseData;
        } catch (error) {
            thunkAPI.dispatch(getVaccinationStatusFailure(error.response.data.Message));
            if (error.response && error.response.status === 401) {
                Cookies.remove("token");

                thunkAPI.rejectWithValue({ unauthorized: true });
                await thunkAPI.dispatch(getToken());
            }
            throw error;
        }
    }
);