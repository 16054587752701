import { createAsyncThunk } from "@reduxjs/toolkit";
import {getPetAncestryStart, getPetAncestrySuccess, getPetAncestryFailure
} from "../reducers/getPetAncestryReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const getPetAncestry = createAsyncThunk(
    "getPetAncestry",
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(getPetAncestryStart());
            const token = Cookies.get("token");

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/PetInsurance/GetAncestry`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const responseData = await response;

            thunkAPI.dispatch(getPetAncestrySuccess(responseData?.data?.result));

            return responseData;
        } catch (error) {
            thunkAPI.dispatch(getPetAncestryFailure(error.response.data.Message));
            if (error.response && error.response.status === 401) {
                Cookies.remove("token");

                thunkAPI.rejectWithValue({ unauthorized: true });
                await thunkAPI.dispatch(getToken());
            }
            throw error;
        }
    }
);