import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import FinProdNav from "./FinProdNav";
import Safetabs from "./Safetabs";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamSafeDeposit = () => {
  return (
    <Fragment>
      <FinProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner safeDepositt">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Safe Deposit Box Takaful</h2>
              <p className="cemter">
                Provides coverage for customers' items of intrinsic value
                contained in safe deposit boxes against risk such as House
                Breaking, Armed Hold up any other Forcible violent means.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <Safetabs />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Safe Deposit Box"} bgImage={`url(${process.env.REACT_APP_URL_ASSETS}Assets/NewBanner/SafeDeposit.jpg)`} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamSafeDeposit;
