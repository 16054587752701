import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Croptabs from "./Croptabs";
import CorProdNav from "../CorporateProductList/CorProdNav";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

function SalamCrop() {
  return (
    <Fragment>
      <CorProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner crop">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Crop Takaful</h2>
              <p className="cemter">
                <b>
                  Farmers are the backbone of the Pakistani economy and Salaam
                  Takaful Limited hopes to be their backbone at the time of
                  need.
                </b>
                <br />
                Salaam Takaful Limited offers Standard Zarai Takaful against
                calamities and Crop Parametric Takaful against weather related
                perils.
                <br />
                <b>
                  Salaam Crop Takaful policy provides coverage for the
                  following:
                </b>
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Croptabs />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Crop"} bgImage={`url(${process.env.REACT_APP_URL_ASSETS}Assets/NewBanner/Crop.jpg)`}/>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamCrop;
