import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTravelBillingInfo } from "../../../Redux/actions/getTravelBillingInfo.js";
import { getTravelTotalContribution } from "../../../Redux/actions/getTravelTotalContribution.js";
const TravelFormSix = ({ onFinishStep6, onFinishFailedSix, handlePrev, form }) => {
  const dispatch = useDispatch();
  const contributionData = useSelector(
    (state) => state.getTravelTotalContribution.data
  );
  const getTravelBillingInfoData = useSelector(
    (state) => state.getTravelBillingInfo.data
  );
console.log(getTravelBillingInfoData, "<=========contributionData");
const formOneData = useSelector((state) => state.postTravelOne.data);
useEffect(() => {
  const Unique_ID = formOneData && formOneData[0]?.Unique_id;
  const PaymentType = "COD";
  dispatch(getTravelBillingInfo({ Unique_ID, PaymentType }));
  // dispatch(getTravelTotalContribution({productId, planId, tenure, dOB, Type}));
}, []);

// const [form] = Form.useForm();
useEffect(() => {
  form.setFieldsValue({
    Gross_Contribution: getTravelBillingInfoData[0]?.Gross_Contribution,
    Policy_Amount: getTravelBillingInfoData[0]?.Policy_Amount,
    Delivery_Charges: getTravelBillingInfoData[0]?.Delivery_Charges,
    Total_Payable: getTravelBillingInfoData[0]?.Total_Payable,
  });
}, [form, getTravelBillingInfoData]);

  return (
    <div
      className="col-sm-8 mForm"
      style={{ backgroundColor: "rgb(30 123 95 / 69%)" }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Billing Information</h5>
        <Form
          name="sehatFormSix"
          onFinish={onFinishStep6}
          onFinishFailed={onFinishFailedSix}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row travelBillingForm">
            <div className="col-sm-12 ">
              {contributionData?.map((item, i) => (
                <div className="travelPackagedeETALS">
                  <p>
                    Package Name: <span>{item?.PLAN_NAME}</span>
                  </p>
                  <p>
                    Package Amount: <span>PKR {item?.AMOUNT}</span>
                  </p>
                </div>
              ))}
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Gross Contribution" name="Gross_Contribution">
                <Input placeholder="Gross Contribution" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Policy Amount" name="Policy_Amount">
                <Input placeholder="Policy Amount" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Delivery Charges" name="Delivery_Charges">
                <Input placeholder="Delivery Charges" readOnly />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item label="Discount Code" name="Dicount_Code">
                <Input placeholder="Discount Code" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item label="Affiliate Code" name="Affiliate_Code">
                <Input placeholder="Affiliate Code" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item label="Total Amount" name="Total_Payable">
                <Input placeholder="Total Amount" readOnly />
              </Form.Item>
            </div>

            <div className="col-sm-6 baaccck">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Proceed To Payment
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  );
};

export default TravelFormSix;
{/* 
<div className="col-sm-6 ">
  <div className="travelPackagedeETALS">
    <p>
      <span>Choose Payment Method</span>
    </p>
    <div className="imagessss">

      <div>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Credit_or_Debit_Card_Flat_Icon_Vector.svg/800px-Credit_or_Debit_Card_Flat_Icon_Vector.svg.png"
          className="creditcard"
        />
        <p>Pay with credit/debit card</p>
      </div>
      <div>
        <img
          src="https://clarity.pk/wp-content/uploads/2017/10/simsim-mobile-wallet-app-review-clarity-pakistan.png"
          className="simsimfinca"
        />
        <p>SIM SIM by FINCA</p>
      </div>
      <div>
        <img src="https://iconape.com/wp-content/png_logo_vector/jazz-cash-logo.png" />
        <p>Jazz Cash</p>
      </div>
      <div>
        <img
          src="https://foree.co/assets/img/logo.png"
          className="foreePay"
        />
        <p>Foree Pay</p>
      </div>
    </div>
  </div>
</div> */}
