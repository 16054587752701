import { createAsyncThunk } from "@reduxjs/toolkit";
import {getPetBreedStart, getPetBreedSuccess, getPetBreedFailure
} from "../reducers/getPetBreedReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const getPetBreed = createAsyncThunk(
    "getPetBreed",
    async (pet_type_id, thunkAPI) => {
        try {
            thunkAPI.dispatch(getPetBreedStart());
            const token = Cookies.get("token");

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/PetInsurance/GetBreedTypes?pet_type_id=${pet_type_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const responseData = await response;

            thunkAPI.dispatch(getPetBreedSuccess(responseData?.data?.result));

            return responseData;
        } catch (error) {
            thunkAPI.dispatch(getPetBreedFailure(error.response.data.Message));
            if (error.response && error.response.status === 401) {
                Cookies.remove("token");

                thunkAPI.rejectWithValue({ unauthorized: true });
                await thunkAPI.dispatch(getToken());
            }
            throw error;
        }
    }
);