import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input } from "antd";
import Fade from "react-reveal/Fade";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import { postNewsLetter } from "../../Redux/actions/postNewsLetter";

function Footer() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = async (values) => {
    await dispatch(postNewsLetter(values));
    form.resetFields();
  };

  const Logo = `${process.env.REACT_APP_URL_ASSETS}Assets/Logo.png`;
  const Arrow = `${process.env.REACT_APP_URL_ASSETS}Assets/img/formarrow.png`;
  const buyn = `${process.env.REACT_APP_URL_ASSETS}Assets/img/buyn.png`;
  return (
    <Fragment>
      <div className="container myfooter hidetab">
        <div className="row">
          <Fade left delay={500}>
            <div className="col-sm-3">
              <img src={Logo} alt="logo" className="footerlogo" />
              <p>
                Salaam Takaful Limited, is an Unlisted Public Limited Company
                incorporated in Pakistan
              </p>
              <p>
                6th floor, Business Centre,Plot No 19-1-A, Block -6. P.E.C.H.S.,
                Shahrah-e-Faisal, Karachi Pakistan
              </p>

              <Link to="tel:021111875111">UAN: 021-111-875-111</Link>
              <Link to="mailto:info@salaaamtakaful.com">
                info@salaaamtakaful.com
              </Link>
            </div>
          </Fade>

          <Fade left delay={500}>
            <div className="col-sm-3">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link
                    to="/claim-form"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Claim Forms
                  </Link>
                </li>
                <li>
                  <Link
                    to="/proposal-form"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Proposal Form
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://online.salaamtakaful.com/e-verify.aspx"
                    target="_blank"
                  >
                    Certificate Verification
                  </Link>
                </li>
                <li>
                  <Link
                    to="/career"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right delay={500}>
            <div className="col-sm-3">
              <h3>Support</h3>
              <ul>
                <li>
                  <Link
                    to="/faq"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Help & FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contactus"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/complaint-resolution-form"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Complaint Resolution
                  </Link>
                </li>
                <li>
                  <Link
                    to="/compain-box"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    Complaint Box
                  </Link>
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right delay={500}>
            <div className="col-sm-3 last">
              <h3>Subscribe</h3>
              <p>
                Get the latest report about the insurance and company profile
              </p>

              <Form
                name="nest-messages"
                onFinish={onFinish}
                form={form}
                style={{ maxWidth: 600 }}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name="Email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input placeholder="E-mail Address" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    <img src={Arrow} alt="" />
                  </Button>
                </Form.Item>
              </Form>
              <div className="secp-img">
                <a href="https://sdms.secp.gov.pk/" target="_blank" rel="noreferrer">
                  <img
                    src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/SECPSDMF.png`}
                    alt="secp"
                  />
                </a>
              </div>

              <div className="secp-img">
                <a href="http://jamapunji.pk/" target="_blank" rel="noreferrer">
                  <img
                    src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/Jamapunji.png`}
                    alt="Jamapunji"
                  />
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="container copyright desktop">
        <div className="row">
          <p>
            © Copyright 2024 SALAAM TAKAFUL LIMITED | Powered by{" "}
            <a
              href="https://avancerasolution.com/"
              rel="noreferrer"
              target="_blank"
            >
              Avancera Solution
            </a>
          </p>
        </div>
      </div>
      {/* mobile footer */}

      <div className="container myfooter showtab">
        <div className="row">
          <Fade up delay={500}>
            <div className="col-sm-12 textcentr">
              <img src={Logo} alt="logo" className="footerlogo" />
              <p>
                Salaam Takaful Limited, is an Unlisted Public Limited Company
                incorporated in Pakistan{" "}
              </p>
              <p>
                6th floor, Business Centre,Plot No 19-1-A, Block -6. P.E.C.H.S.,
                Shahrah-e-Faisal, Karachi Pakistan
              </p>

              <a href="tel:021111875111">UAN: 021-111-875-111</a>
<a href="mailto:support@salaamtakful.com">support@salaamtakful.com</a>
            </div>
          </Fade>

          <div className="col-sm-12">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Quick Links</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <Link
                        to="/claim-form"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Claims Forms
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/proposal-form"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Proposal Form
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://online.salaamtakaful.com/e-verify.aspx"
                        target="_blank"
                      >
                        Certificate Verification
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/career"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Support</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <Link
                        to="/faq"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Help & FAQs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contactus"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/complaint-resolution-form"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Complaint Resolution
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/compain-box"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Complaint Box
                      </Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Subscribe</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Get the latest report about the insurance and company
                    profile
                  </p>

                  <Form
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                    form={form}
                    validateMessages={validateMessages}
                  >
                    <Form.Item
                      name="Email"
                      rules={[{ type: "email", required: true }]}
                    >
                      <Input placeholder="E-mail Address" />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {" "}
                        <img src={Arrow} alt="" />{" "}
                      </Button>
                    </Form.Item>
                  </Form>
                  <div className="secp-img">
                    <a href="https://sdms.secp.gov.pk/" target="_blank" rel="noreferrer">
                      <img
                        src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/SECPSDMF.png`}
                        alt="secp"
                      />
                    </a>
                  </div>
                  <div className="secp-img">
                    <a href="http://jamapunji.pk/" target="_blank" rel="noreferrer">
                      <img
                        src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/jamapunji.jpg`}
                        alt="jamapunji"
                      />
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="container copyright mobile">
        <div className="row">
          <p>© Copyright 2024 SALAAM TAKAFUL LIMITED</p>
          <p>
            Powered by{" "}
            <a href="https://avancerasolution.com/" target="_blank" rel="noreferrer">
              Avancera Solution
            </a>
          </p>
        </div>
      </div>

      <div className="container foots banner ">
        <div className="row">
          <div className="col-sm-6 buttonscta">
            <Fade right delay={800}>
              <Link
                to="/buynow"
                className="buy"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                <img src={buyn} width="40" height="40" />
                Buy Now
              </Link>
            </Fade>
            {/* <Fade left delay={800}>
              <a href="tel:123456789" className="call onBMobilee">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/7887/7887071.png"
                  width="20"
                  height="20"
                />
                Call Us
              </a>
            </Fade> */}

          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
