import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getVaccinationStatus } from "../../../Redux/actions/getVaccinationStatus";

const PetFormThree = ({ onFinishFailedThree, onFinishStep3, form, handlePrev }) => {
    const { Option } = Select;
    const dispatch = useDispatch();

    const { data: VaccinationStatus } = useSelector((state) => state.getVaccinationStatus);
    useEffect(() => {
        dispatch(getVaccinationStatus());
    }, [dispatch]);

    return (
        <div className="col-sm-8 mForm" style={{ backgroundColor: "rgb(30 123 95 / 69%)" }} >
            <div className="centreWidthFOrm">
                <h3 className="cnhbsh3">Salaam Pet Takaful</h3>
                <h5 className="cnhbsh5">Additional Details</h5>
                <Form
                    name="sehatFormOne"
                    onFinish={onFinishStep3}
                    onFinishFailed={onFinishFailedThree}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                >
                    <div className="row">
                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="MICROCHIP_NUMBER"
                                label="Microchip Number (if applicable)"
                            >
                                <Input placeholder="Microchip Number" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="VACCINATION_STATUS"
                                label="Vaccination Status"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Vaccination Status!"
                                    },
                                ]}
                            >
                                <Select placeholder="Vaccination Status">
                                    {VaccinationStatus[0]?.map((item, i) => (
                                        <Option value={item?.ID}>{item?.VaccinationStatus}</Option>
                                    ))}
                                    {/* <Option value="Uptodate">Up to date</Option>
                                    <Option value="Notuptodate">Not up to date</Option> */}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="SpayedNeutered"
                                label="Spayed/ Neutered"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Spayed/ Neutered!"
                                    }
                                ]}
                            >
                                <Select placeholder="Spayed/ Neutered">
                                    <Option value="Y">Yes</Option>
                                    <Option value="N">No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="SPECIFIC_DIET"
                                label="Specific Diet or Food Allergies"

                            >
                                <Input placeholder="Specific Diet" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="BEHAVIOURAL_CONCERNS"
                                label="Behavioural Concerns"
                                rules={[
                                    {
                                        required: true,
                                        message: "Behavioural Concerns is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Behavioural Concerns" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6 labelSSS">
                            <Form.Item
                                name="VETERINARY_CARE_CONTACT"
                                label="Veterinary Care Contact"
                                rules={[
                                    {
                                        required: true,
                                        pattern: /^03\d{2}-\d{7}$/,
                                        message: "Veterinary Care Contact number must be in this format 03XX-XXXXXXX",
                                    },
                                ]}
                                normalize={(value) => {
                                    if (!value) {
                                        return value;
                                    }
                                    const onlyNums = value.replace(/[^\d]/g, "");
                                    if (onlyNums.length <= 4) {
                                        return onlyNums;
                                    } else if (onlyNums.length <= 11) {
                                        return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                                    }
                                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                                }}
                            >
                                <Input placeholder="Veterinary Care Contact 03XX-XXXXXXX" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-6">
                            <Form.Item>
                                <Button onClick={handlePrev}>Back</Button>
                            </Form.Item>
                        </div>

                        <div className="col-sm-6">
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ background: "#1C75BC" }}>
                                    Next
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default PetFormThree;