import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Productsnav from "./Productsnav";
import PetTab from "./PetTab";
import WhyChosePet from "../WhychoseUs/WhyChosePet";
import MainForm from "./PetForms/MainForm";

function SalamPetTakaful() {
  return (
    <Fragment>
      <Productsnav />
      <div className="container tabs motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner petTakaful">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Pet Takaful</h2>
              <p className="cemter">Protect Your Furry Friends with Salaam Pet Takaful</p>
              <p className="cemter"><span>Peace of mind for your beloved companions.</span> Salaam Pet Takaful offers Shariah-compliant protection for pets, ensuring their well-being and your financial security. With coverage tailored to Islamic principles, safeguard your pets against unexpected illnesses, injuries, and more.</p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <PetTab />
          </div>
          <div className="col-sm-12 motorForm">
            <MainForm />
          </div>

          <div className="col-sm-12 heads text">
            <WhyChosePet />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamPetTakaful;
