import React, { Fragment, useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import Fade from "react-reveal/Fade";
import { SalaamDirectors } from "../../Data/Data";
import AOS from "aos";
import "aos/dist/aos.css";

function Directorbox() {
  const [isFlipped, setIsFlipped] = useState(
    Array(SalaamDirectors.length).fill(false)
  );

  const handleClick = (index) => {
    setIsFlipped((prevState) => {
      const newFlipped = [...prevState];
      newFlipped[index] = !newFlipped[index];
      return newFlipped;
    });
  };

  useEffect(() => {
    AOS.refresh();
  }, [isFlipped]);

  return (
    <Fragment>
      <div>
        <h2 data-aos="fade-right" data-aos-duration="4000">
          Board Of Directors
        </h2>
      </div>
      <div className="container governanceData">
        <div className="row">
          {SalaamDirectors.map((data, index) => (
            <div className="col-sm-4" key={index}>
              <Fade down delay={500}>
                <ReactCardFlip
                  isFlipped={isFlipped[index]}
                  flipDirection={data.animation}
                  containerStyle={{ height: "100%" }}
                >
                  <div>
                    <button onClick={() => handleClick(index)}>
                      <img src={data.image} alt="directors" />
                      <h3 className="dname">{data.name}</h3>
                      <p className="designation">{data.designation}</p>
                    </button>
                  </div>

                  <div>
                    <button
                      onClick={() => handleClick(index)}
                      className="backbox"
                    >
                      <Fade left>
                          <p>
                            {data.detail}
                          </p>
                        </Fade>
                      {data?.detail1 && (
                        <Fade left>
                          <p>
                            {data.detail1}
                          </p>
                        </Fade>
                      )}
                      {data?.detail2 && (
                        <Fade left>
                        <p>
                          {data.detail2}
                        </p>
                      </Fade>
                      )}

                      {data?.detail3 && (
                        <Fade left>
                        <p>
                          {data.detail3}
                        </p>
                      </Fade>
                      )}

                      {data?.detail4 && (
                        <Fade left>
                        <p>
                          {data.detail4}
                        </p>
                      </Fade>
                      )}

                      {data?.detail5 && (
                        <Fade left>
                        <p>
                          {data.detail5}
                        </p>
                      </Fade>
                      )}

                      {data?.detail6 && (
                        <Fade left>
                        <p>
                          {data.detail6}
                        </p>
                      </Fade>
                      )}
                    </button>
                  </div>
                </ReactCardFlip>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Directorbox;
