import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Complaintext from "./Complaintext";
import Complainform from "./Complainform";
import CTA from "./CTA";

function ComplainBanner() {
  return (
    <Fragment>
      <div className="col-sm-12 heads govbanner complaint">
        <Fade up delay={500}>
          <h2 className="oswald">Complaint Box</h2>
        </Fade>
      </div>

      <div className="container tabss motor newShariaah">
        <div className="row">
          <div className="col-sm-12 heads text mymaxheight shaaariaah complainData">
            <Complaintext />
          </div>

          <div className="col-sm-12 heads text mymaxheight  complainSection">
            <Complainform />
          </div>

          <div className="col-sm-12 heads text mymaxheight cta">
            <CTA />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ComplainBanner;
