import React from "react";
import { Fragment } from "react";

import Fade from "react-reveal/Fade";
import { shariahData } from "../../Data/Data";
import ReactCardFlip from "react-card-flip";
import { useState } from "react";
import { useEffect } from "react";

function SharaihBoard({ isActive }) {
  const [isFlipped, setIsFlipped] = useState(
    Array(shariahData.length).fill(false)
  );
  const handleClick = (index) => {
    setIsFlipped((prevState) => {
      const newFlipped = [...prevState];
      newFlipped[index] = !newFlipped[index];
      return newFlipped;
    });
  };

  useEffect(() => {
    setIsFlipped(Array(shariahData.length).fill(false));
  }, [isActive]);

  return (
    <Fragment>
      <div className="container governanceData shariahData govdirectors ">
        <div className="row shhariah">
          {shariahData.map((data, index) => (
            <div className="col-sm-4" key={index}>
              <Fade down delay={500}>
                <ReactCardFlip
                  isFlipped={isFlipped[index]}
                  flipDirection={data.animation}
                  containerStyle={{ height: "100%" }}
                >
                  <div>
                    <button onClick={() => handleClick(index)}>
                      <img src={data.image} alt="directors" />
                      <h4 className="dname">{data.name}</h4>
                      <p className="designation">{data.designation}</p>
                    </button>
                  </div>

                  <div>
                    <button onClick={() => handleClick(index)} className="backbox" dangerouslySetInnerHTML={{ __html: data.detail }} />
                  </div>
                </ReactCardFlip>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default SharaihBoard;