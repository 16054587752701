import React, { useEffect } from "react";
import { Button, Form, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getLiveIn } from "../../../Redux/actions/getLiveIn";
import { getHomeUnit } from "../../../Redux/actions/getHomeUnit";
import { getHomeOwnerShip } from "../../../Redux/actions/getHomeOwnerShip";
import { getHomeCity } from "../../../Redux/actions/getHomeCity";

const HomeFormTwo = ({
  onFinishStep2,
  onFinishFailedTwo,
  handlePrev,
  form,
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const livesinData = useSelector((state) => state.getLiveIn.data);
  const homeUnitData = useSelector((state) => state.getHomeUnit.data);
  const homeOwnerData = useSelector((state) => state.getHomeOwner.data);
  const homeCityData = useSelector((state) => state.getHomeCity.data);

  useEffect(() => {
    dispatch(getLiveIn());
    dispatch(getHomeUnit());
    dispatch(getHomeOwnerShip());
    dispatch(getHomeCity());
  }, []);
  return (
    <div className="col-sm-8 mForm" style={{ backgroundColor: "rgb(30 123 95 / 69%)" }} >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Home Takaful</h3>
        <h5 className="cnhbsh5">Home Information</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep2}
          onFinishFailed={onFinishFailedTwo}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-sm-12 labelSSS">
              <Form.Item
                name="LIVEIN"
                label="Lives In"
                rules={[
                  {
                    required: true,
                    message: "Please select an option!",
                  },
                ]}
              >
                <Select
                  placeholder="Lives In"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {livesinData?.map((item, i) => (
                    <Option value={item?.ID}>{item?.LiveIn}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="AREA"
                label="Area"
                rules={[
                  {
                    required: true,
                    message: "Area is required!",
                  },
                ]}
              >
                <InputNumber placeholder="Area" min={1} />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="AREA_UNIT"
                label="Area Unit"
                rules={[
                  {
                    required: true,
                    message: "Please select area units!",
                  },
                ]}
              >
                <Select
                  placeholder="Area Unit"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {homeUnitData?.map((item, i) => (
                    <Option key={i} value={item?.ID}>
                      {item?.Unit}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="OWNER"
                label="Owner Ship"
                rules={[
                  {
                    required: true,
                    message: "Please Select House Type!",
                  },
                ]}
              >
                <Select
                  placeholder="Owner Ship"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {homeOwnerData?.map((item, i) => (
                    <Option key={i} value={item?.ID}>
                      {item?.Ownership}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CITY"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please Select City!",
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {homeCityData?.map((item, i) => (
                    <Option key={i} value={item?.city_code}>
                      {item?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Button onClick={handlePrev}>Back</Button>
            </div>
            <div className="col-sm-6">
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1C75BC" }}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormTwo;
